<template>
  <div class="hygiene">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>医疗卫生</h3>
      <p>Medical care</p>
    </div>
    <!-- head -->
    <!-- content -->
    <Content :data="content" />
    <!-- content -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [
        {
          imgSrc: require("@/assets/img/hygiene2.jpg"),
          title: "新冠疫苗接种大数据平台",
          text: " 为安徽省某地市疾控中心开发建设《新冠疫苗接种大数据平台》，对接预防接种系统，将实时接种数据，按区县、按接种点、按日期对接种情况进行分类统计，并综合数据分析，提供可视化大屏，让疾控中心和市卫健委可以实时了解新冠疫苗接种情况。在新冠疫苗接种初期的接种情况跟踪，辅助领导层决策起到了关键性作用。",
          careArr: ["应急需求，极速响应开发部署", "灵活报表，按需定制，动态更新", "安全可靠，多层加密，坚固安全", "大屏可视，关键数据一目了然"],
          iconArr: [require("@/assets/img/icon23.png")],
        },
        {
          imgSrc: require("@/assets/img/hygiene3.jpg"),
          title: "儿童疫苗接种预约系统",
          text: "改变传统儿童预防接种管理模式，将儿童接种过程中的预约、登记、排号、签字等大部分由工作人员在系统中完成的，转变成让受种人自主完成，将“管理型模式”向“服务型模式”转变。《儿童预防接种预约系统》为儿童家长提供了一个自主、自助的疫苗接种服务，包括接种前的查询、建档、预约；接种时的自检、签字、在线缴费、接种叫号等；接种后的留观计划、下针预约、异常反应咨询等功能。",
          careArr: [
            "提出了一种智慧预防接种管理新模式",
            "减轻了接种门诊工作人员负担，解放双手让他们更关注于接种本身",
            "加强了接种人群与疾控部门的信息沟通",
            "提供了一个个性化的安全接种全过程服务",
          ],
          iconArr: [require("@/assets/img/icon23.png")],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .hygiene {
    position: relative;
    height: 100%;
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../../assets/img/hygiene-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.45rem;
        margin-left: 7.5rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .hygiene {
    position: relative;
    height: 100%;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../../assets/img/hygiene-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        // width: 4.5rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
        text-align: center;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        text-align: center;
      }
    }
  }
}
</style>
